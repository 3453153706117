<template>
    <div class="flex mx-5 my-5">
        <SideBar :btnText="'+ 新增教戰守則'" @clickBtn="addRule" />
        <div class="pb-5 flex-1">
            <div class="shadow-lg bg-white rounded-lg ml-3 pb-5 relative">
                <LoadingComponent :isLoading="loading" />
                <div class="overflow-x-auto">
                    <table class="myTable">
                        <thead>
                            <tr>
                                <th>內容</th>
                                <th>分類</th>
                                <th>使用期間</th>
                                <th>首頁顯示</th>
                                <th v-permission="['update']">編輯</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in datas" :key="index" class="text-center duration-200 transition">
                                <td class="show-ellipsis">{{ item.text }}</td>
                                <td>
                                    {{ formatCategories(item.category) }}
                                </td>
                                <td>
                                    <span v-if="item.start_date || item.end_date">
                                        {{ item.start_date | formatDate }} -
                                        {{ item.end_date | formatDate }}
                                    </span>
                                    <span v-else> 不限使用期間 </span>
                                </td>
                                <td>
                                    <el-checkbox
                                        :key="item.id"
                                        :checked="hasChecked(item.id)"
                                        :disabled="hasDisabled(item.id) || !$route.meta.params.Update"
                                        @change="changePin($event, item.id)"></el-checkbox>
                                </td>
                                <td v-permission="['update']" class="cursor-pointer" @click="goEdit(item.id)">
                                    <i class="far fa-edit"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="justify-center mt-3 flex mb-3">
                        <MyPagination
                            :paginationData="paginationData"
                            :currentPage="currentPage"
                            @onPageSizeChange="pageSizeChange"
                            @onCurrentPageChange="currentPageChange" />
                    </div>
                    <div class="justify-center mt-5 flex mb-3">
                        <button v-permission="['update']" class="orange-btn-800-lg text-md" @click="updatePin">儲存變更</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
import SideBar from "../components/SideBar.vue";
export default {
    name: "FightingRules",
    components: {
        MyPagination,
        LoadingComponent,
        SideBar,
    },
    computed: {
        // 統整 getlist api filters
        filterData() {
            const result = {
                limit: this.paginationData.limit,
                page: this.currentPage,
            };
            return result;
        },
    },
    data() {
        return {
            datas: [],
            loading: false,
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 1,
            checked: [],
            // 分類
            categories: {},
        };
    },
    methods: {
        // 新增
        addRule() {
            this.$router.push({ name: "add_rule" });
        },
        // 編輯
        goEdit(id) {
            this.$router.push({
                path: `/homepage_setting/fighting_rules/edit/${id}`,
            });
        },
        // 切頁相關
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        async getList() {
            this.loading = true;
            const { status, data } = await this.$api
                .GetGuidelinesList(this.filterData)
                .then((res) => {
                    return { status: res.status, data: res.data };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status == 200) {
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            }
        },
        // 取得所有 pin 的資料
        async getAllPin() {
            const { status, data } = await this.$api
                .GetGuidelinePinned()
                .then((res) => {
                    return { status: res.status, data: res.data.guidelines };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status == 200) {
                this.checked = [];
                data.forEach((i) => {
                    this.checked.push(i.id);
                });
            }
        },
        hasChecked(id) {
            return this.checked.indexOf(id) !== -1;
        },
        hasDisabled(id) {
            // 可以消除
            if (this.checked.indexOf(id) !== -1) return false;
            // 超過數列無法新增
            if (this.checked.length === 5) return true;
            return false;
        },
        changePin(val, id) {
            if (val) {
                this.checked.push(id);
            } else {
                this.checked = this.checked.filter((i) => i !== id);
            }
        },
        updatePin() {
            const req = {
                ids: [...this.checked],
            };
            this.$api
                .UpdatePinnedGuideline(req)
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "更新成功",
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "更新失敗",
                    });
                });
        },
        // 取得分類
        async getAllGategories() {
            await this.$api.getGategories().then((res) => {
                res.data.categories.forEach((item) => {
                    this.categories[item.id] = item.name;
                });
            });
        },
        formatCategories(idx) {
            return this.categories[idx];
        },
    },
    async activated() {
        await this.getAllGategories();
        await this.getAllPin();
        await this.getList();
    },
    async mounted() {
        await this.getAllGategories();
        await this.getAllPin();
        await this.getList();
    },
};
</script>

<style lang="scss" scoped>
.myTable {
    width: 100%;
    tbody {
        tr .show-ellipsis {
            max-width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
</style>
